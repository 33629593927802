import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EncrDecrServiceService } from '@project/services/encr-decr-service.service';
import { EventService } from '@project/services/event.service';
import { SocalloginService } from '@project/services/socallogin.service';
import { StorageService } from '@project/services/storage.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-register-new',
  templateUrl: './register-new.component.html',
  styleUrls: ['./register-new.component.scss']
})
export class RegisterNewComponent implements OnInit {

  @ViewChild('stepper') private myStepper: MatStepper;
  primaryInfo: UntypedFormGroup;
  securityForm: UntypedFormGroup;
  countries: any;
  businessInfo: UntypedFormGroup;
  billingInfo: UntypedFormGroup;
  businesstypes: any;
  imageSrc: string;
  securityFm = false;
  businessInfoFm = false;
  billingInfoFm = false;
  otpField: any;
  OtpResp: any;
  documentIdName: any;
  verificationDocumentName: any;
  timeLeft: number;
  countryCode: any;
  resData: any;
  linearFlag: boolean = true;


  constructor(
    private apiService: ApiService,
    private storage: StorageService,
    private event: EventService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private EncrDecr: EncrDecrServiceService,
    private fb_login: SocalloginService
  ) { }
  startTimer() {
    this.timeLeft = 60 * 5;
    setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.fb_login.gmailInit()

    if (this.storage.getDataField('token')) {
      this.router.navigate(['/profile']);
    }
    this.event.isLogin.subscribe((isLogedIn: boolean) => {
      if (isLogedIn === true && this.storage.getData('navigateData') == undefined) {
        this.router.navigate(['/profile']);
      }
    });
    this.event.socialData.subscribe((res: any) => {
      if (res) {
        this.socialLogin(res);
      }
    })
    this.event.isSignChangeData.subscribe((res: any) => {
      if(res){
        this.linearFlag = false;
        this.onSend(res);
        this.resData = res;
      }
    });
    this.formInit();
    this.getCountries();
  }

  formInit() {
    this.primaryInfo = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],

      // mobile: [''],
      country: ['', [Validators.required]],
      // otpType: ['both']
    });
    // this.securityForm = this.fb.group({
    //   password: ['', [Validators.required, Validators.minLength(8)]],
    //   emailOTP: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    //   mobileOTP: ['', [Validators.minLength(4), Validators.maxLength(4)]],
    // });
  }
  socialLogin(data: any) {
    this.apiService.post('user/social/signup', data).pipe(take(1)).subscribe((res: any) => {
      if (res.status === 200) {
        this.event.socialData.next(false)
        const data = {
          token: res.token,
          name: res.data.name,
          userId: res.data._id,
          social_id: res?.data?.social_id
        };
        this.storage.setUser(data).then(() => {
          this.event.setLoginEmmit(true);
        });

        if (this.storage.getData('navigateData') != undefined) {
          let url = this.storage.getData('navigateData');
          if (url == 'cart') {
            this.router.navigate(['/checkout']);
            setTimeout(() => {
              window.location.reload();
            }, 50);
          } else {
            this.router.navigateByUrl(url);
            setTimeout(() => {
              window.location.reload();
            }, 50);
          }
          localStorage.removeItem('navigateData');

        } else {
          this.router.navigate(['/profile']);
          setTimeout(() => {
            window.location.reload();
          }, 50);
        }
        this.apiService.alert(res.message, 'success')
      }
      else {
        this.apiService.alert(res.message, 'warning')
      }
    }, err => {
      this.apiService.alert(err, 'error')
    })
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  getCountries() {
    this.apiService.get(`masterdata/countries`).subscribe((res: any) => {
      this.countries = res.data;
    });
  }
  // goForward(stepper: MatStepper, formName: string) {
  //   if (formName === 'primaryInfo') {
  //     if (this.primaryInfo.valid) {
  //       const payLoad = {
  //         type: this.primaryInfo.value.mobile != "" ? this.primaryInfo.value.otpType : 'email',
  //         email: this.primaryInfo.value.email,
  //         mobile: this.primaryInfo.value.mobile,
  //         name: this.primaryInfo.value.name,
  //         country: this.primaryInfo.value.country
  //       };

  //       this.apiService.post(`user/sendotp`, payLoad).subscribe((resp: any) => {
  //         if (resp.status === 200) {
  //           this.OtpResp = resp.data;
  //           this.apiService.alert(resp.message, 'success', 10000);
  //           stepper.next();
  //           this.startTimer();
  //         } else {
  //           this.apiService.alert(resp.message, 'warning');
  //           return;
  //         }
  //       }, err => {

  //         this.apiService.alert(err?.message, 'error')
  //       });
  //     } else {
  //       this.primaryInfo.markAllAsTouched();
  //     }
  //   }

  // }

  goForward(stepper: MatStepper, formName: string) {
    if (formName === 'primaryInfo') {
      if (this.primaryInfo.valid) {
        const payLoad = {
          email: this.primaryInfo.value.email,
          // mobile: this.primaryInfo.value.mobile,
          // name: this.primaryInfo.value.name,
          firstName: this.primaryInfo.value.firstName,
          lastName: this.primaryInfo.value.lastName,
          password: this.primaryInfo.value.password,
          country: this.primaryInfo.value.country
        };

        this.apiService.post(`user/signup`, payLoad).subscribe((resp: any) => {
          if (resp.status === 200) {
            this.OtpResp = resp.data;
            this.resData = resp.data?.email;
            // this.emailSend(resp?.data)
            let data = {
              email: resp.data?.email
            }
            this.apiService.post(`user/verification-email/send`, data).subscribe((resp: any) => {
              if (resp.status === 200) {
                this.apiService.alert(resp.message, 'success', 10000);
                stepper.next();
                this.startTimer();
              } else {
                this.apiService.alert(resp.message, 'warning');
                return;
              }
            }, err => {

              this.apiService.alert(err?.message, 'error')
            });
            // this.apiService.alert(resp.message, 'success', 10000);
          } else {
            this.apiService.alert(resp.message, 'warning');
            return;
          }
        }, err => {

          this.apiService.alert(err?.message, 'error')
        });
      } else {
        this.primaryInfo.markAllAsTouched();
      }
    }

  }
  // emailSend(emailData:any){
  //   let data ={
  //     email: emailData?.email
  //   }
  //   this.apiService.post(`user/verification-email/send`, data).subscribe((resp: any) => {
  //     if (resp.status === 200) {
  //       this.apiService.alert(resp.message, 'success', 10000);
  //       this.startTimer();
  //     } else {
  //       this.apiService.alert(resp.message, 'warning');
  //       return;
  //     }
  //   }, err => {

  //     this.apiService.alert(err?.message, 'error')
  //   });
  // }

  onSend(dataEmail:any) {
    let data = {
      email: dataEmail
    }
    this.apiService.post(`user/verification-email/send`, data).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.apiService.alert(resp.message, 'success', 10000);
        this.myStepper.next();
        this.startTimer();
      } else {
        this.apiService.alert(resp.message, 'warning');
        return;
      }
    }, err => {

      this.apiService.alert(err?.message, 'error')
    });
  }
  resendOTP() {
    // this.emailSend(resp?.data)
    let data = {
      email: this.resData
    }
    this.apiService.post(`user/verification-email/resend`, data).subscribe((resp: any) => {
      if (resp.status === 200) {
        // this.OtpResp = resp.data;
        this.apiService.alert(resp.message, 'success');
        this.startTimer();
      } else {
        this.apiService.alert(resp.message, 'warning');
        return;
      }
    });
  }



  onCountryClicked() {
    this.countryCode = undefined;
  }

  onCountryChange(ev: any) {
    this.countries.map((x: any) => {
      if (x._id === ev.value) {
        this.countryCode = x.countryCode.toLowerCase();
      }
    })
  }


  submit() {
    if (this.securityForm.valid) {
      const payLoad = {
        type: this.primaryInfo.value.mobile != "" ? this.primaryInfo.value.otpType : 'email',
        emailOTP: this.securityForm.value.emailOTP,
        mobileOTP: this.securityForm.value.mobileOTP,
        userId: this.OtpResp?.saveUser?._id,
      };
      this.apiService.post(`user/verifyotp`, payLoad).subscribe((resp: any) => {
        if (resp.status === 200) {
          this.apiService.alert(resp?.message, 'success');
          if (this.primaryInfo.valid) {
            this.register();
          } else {
            this.securityForm.markAllAsTouched();
          }
        } else {
          this.apiService.alert(resp.message, 'error');
        }
      });
    } else {
      this.securityForm.markAllAsTouched();
    }

  }

  register() {
    const data = {
      name: this.primaryInfo?.value.name,
      email: this.primaryInfo?.value.email,
      password: this.securityForm?.value.password,
      mobile: this.primaryInfo?.value.mobile,
      country: this.primaryInfo?.value.country,
      userId: this.OtpResp?.saveUser?._id
    }
    this.apiService.post(`user/register`, data).subscribe((res: any) => {
      if (res.status === 200) {
        this.apiService.alert('Registered successfully.', 'success');
        this.primaryInfo.reset();
        this.router.navigate(['/login']);
      } else {
        this.apiService.alert(res.message, 'error');
      }
    });
  }

  submitLogin() {
    this.fb_login.submitLogin();
  }



}
