// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // dev
  // BASE_API_URL: 'https://shopdropnew-admin.dedicateddevelopers.us/frontend/',
  // BASE_API_URL: 'https://admin-shopdrop.dedicatedresource.net/frontend/',
  // IMAGE_BASE_URL1: 'https://shopdropnew-admin.dedicateddevelopers.us/uploads/',

  // IMAGE_BASE_URL1: 'https://shopdropnew-admin.dedicatedresource.net/uploads/',
  // IMAGE_BASE_URL2: 'https://shopdropnew-admin.dedicatedresource.net/',
  // IMAGE_BASE_URL: 'https://shopdrop.s3.af-south-1.amazonaws.com/',
  // IMAGE_BASE_URL: 'https://d2fnmg1h87nxsl.cloudfront.net/',
  // BASE_API_URL: 'https://admin.shopdrop.shop/frontend/',
  // IMAGE_BASE_URL1: 'https://admin.shopdrop.shop/uploads/',
  // IMAGE_BASE_URL: 'https://shopdropcdn.dedicatedresource.net/',
  // LOCAL_IMAGE_URL: 'https://admin-shopdrop.dedicatedresource.net/',


  // LIVE URLS

  BASE_API_URL: 'https://admin.shopdrop.shop/frontend/',
  IMAGE_BASE_URL1: 'https://admin.shopdrop.shop/uploads/',
  IMAGE_BASE_URL: 'https://cdn.shopdrop.shop/',
  BLOG_API_URL: 'https://shopdrop-blog.dedicateddevelopers.us/wp-json/',
  GOOGLE_CLIENT_ID: "389711377566-37phu9h6jprbufq41r095tljkoc1nke3.apps.googleusercontent.com",
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.



//  for build

// 1. ng build 
// 2. ng run ShopDrop:server:production
