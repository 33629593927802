import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-verified-email',
  templateUrl: './verified-email.component.html',
  styleUrls: ['./verified-email.component.scss']
})
export class VerifiedEmailComponent implements OnInit {
  urlToken: any;
  ID: any;
  varifyFlag:boolean=false;
  constructor(private route: ActivatedRoute,private apiService:ApiService,private storageService:StorageService,private router: Router) { }

  ngOnInit(): void {
    this.route.snapshot.params['id'] && (this.ID = this.route.snapshot.params['id']);
    // console.log(this.ID);
    this.emailvarify();
    // this.setForgotUserToken();
  }
  emailvarify(){
    let data ={
      token: this.ID
    }
    this.apiService.post(`user/email/verify`, data).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.apiService.alert(resp.message, 'success', 10000);
        this.varifyFlag = true;
      } else {
        this.apiService.alert(resp.message, 'warning');
        return;
      }
    }, err => {

      this.apiService.alert(err?.message, 'error')
    });
  }

  setForgotUserToken():any {
    this.route.queryParams.subscribe((params) => {
      if(params.token){
        this.urlToken = params.token;
        // console.log(this.urlToken);
        
        // this.storageService.setUser({token:this.urlToken});
        return true;
      } else {
        this.apiService.alert('This page has been expire','error');
        return false;
      }
    })
  }

}
