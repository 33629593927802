<div class="page-verify-email">
    <figure>
        <img src="./assets/images/email.webp" alt="">
    </figure>
    <div class="verify-content" *ngIf="!varifyFlag">
        <!-- <h1> Email Verified</h1> -->
        <p>
           Your email is verifing...
        </p>
        <!-- <button routerLink="/login">Sign in Now</button> -->
    </div>
    <div class="verify-content" *ngIf="varifyFlag">
        <h1>Email Verified</h1>
        <p>
            <mat-icon>check_circle</mat-icon> Thank you for veryfying your email and keeping your Shopdrop account.
        </p>
        <button routerLink="/login">Sign in Now</button>
    </div>
</div>
