import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  remembar = {
    KEY: 'project_auth02',
    PASSWORD: '!##0895*()?:}95047834&&tesdsfdsfsdf@EWdsd'
  };

  TEMP = {
    KEY: '_listing_search_data',
    PASSWORD: '90590348534YYIU!@00'
  };
  USER = {
    KEY: '_project_auth_0',
    PASSWORD: '!##0895*()?:}95047834&&tes'
  };


  constructor(
    private cookie: CookieService
  ) { }


  encription(data: any, secret: string): any {
    return CryptoTS.AES.encrypt(JSON.stringify(data), secret);
  }
  decription(data: any, secret: string): any {
    const bytes = CryptoTS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
  }

  setTempData(DATA: any): any {
    return this.cookie.set(this.TEMP.KEY, this.encription(DATA, this.TEMP.PASSWORD).toString());
  }

  getTempData(): any {
    const DATA = this.cookie.get(this.TEMP.KEY) !== null ? this.cookie.get(this.TEMP.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TEMP.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearTempData(): any {
    return this.cookie.delete(this.TEMP.KEY);
  }

  async setUser(data: any): Promise<any> {
    return this.cookie.set(this.USER.KEY, this.encription(data, this.USER.PASSWORD).toString(), 365, '/', '', false, 'Strict');
  }

  getUser(): any {
    const DATA = this.cookie.get(this.USER.KEY) !== null ? this.cookie.get(this.USER.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearUser(): any {
    return this.cookie.delete(this.USER.KEY);
  }


  async setData(data: any, name: any) {
    return localStorage.setItem(
      name,
      this.encription(data, "localStorage").toString()
    );
  }

  getData(name: any) {
    const data = localStorage.getItem(name);
    if (data && data !== undefined) {
      return this.decription(data, "localStorage");
    } else {
      return undefined;
    }
  }

  getDataField(type: string): any {
    if (this.getUser() !== undefined && this.getUser()[type] !== undefined) {
      return this.getUser()[type];
    } else {
      return false;
    }
  }

  isAuthenticate(): boolean {
    if (this.getDataField('token') && this.getDataField('token') !== undefined) {
      return true;
    } else {
      return false;
    }
  }


  setCredential(data: any) {
    return this.cookie.set(this.remembar.KEY, this.encription(data, this.remembar.PASSWORD).toString(), 365, '/');
  }

  getCredential() {
    const DATA = this.cookie.get(this.remembar.KEY) !== null ? this.cookie.get(this.remembar.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.remembar.PASSWORD);
    } else {
      return false;
    }
  }

  clearCredential() {
    this.cookie.delete(this.remembar.KEY, '/');
  }

}
