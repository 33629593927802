import { Component, HostListener, OnInit, PLATFORM_ID, Inject, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { SocalloginService } from '@project/services/socallogin.service';
import { StorageService } from '@project/services/storage.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@envconfig';


@Component({
  selector: 'app-login-new',
  templateUrl: './login-new.component.html',
  styleUrls: ['./login-new.component.scss']
})
export class LoginNewComponent implements OnInit, AfterViewInit {
  loginForm: UntypedFormGroup;
  baseImgUrl: string;
  isLoader: boolean = false;
  gestId: any;
  cyodData: any;
  detailObj: any;
  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    private storage: StorageService,
    private event: EventService,
    private router: Router,
    private fb_login: SocalloginService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { this.baseImgUrl = environment.IMAGE_BASE_URL; }
  google: any

  ngOnInit(): void {
    this.fb_login.gmailInit()
    this.formInit();
    
    this.event.isLogin.subscribe((isLogedIn: boolean) => {
      if (isLogedIn === true && this.storage.getData('navigateData') == undefined) {
        this.router.navigate(['/profile']);
      }
    });
    this.event.socialData.subscribe((res: any) => {
      if (res) { this.socialLogin(res); }
    })
    if (window.innerWidth <= 767) {
      if (isPlatformBrowser(this.platformId)) {
        let header = document.getElementsByClassName('page_header');
        header[0].setAttribute('style', 'display:none');
        // let footer = document.getElementsByClassName('footer');
        // footer[0].setAttribute('style', 'display:none');
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        let header = document.getElementsByClassName('page_header');
        header[0].setAttribute('style', 'display:block');
        // let footer = document.getElementsByClassName('footer');
        // footer[0].setAttribute('style', 'display:block');
      }
    }

  }

  ngAfterViewInit(): void {
    this.gestData();
  }



  gestData() {
      this.gestId = localStorage.getItem('gest_id');
      if (localStorage.getItem('gest_id')) {
        
        if (this.gestId) {
          this.api.get(`guest-user/details/${this.gestId}`).subscribe((res: any) => {
            if (res.status == 200) {
              let data = JSON.parse(res.data.cyod_value)
              this.cyodData = data.cyodNotSave;
              this.detailObj = data.detailObj;
            }
          });
        }
      }

  }





  socialLogin(data: any) {
    // let cyodData: any
    // if (localStorage.getItem('cyodNotSave')) {
    //   cyodData = JSON.parse(localStorage.getItem('cyodNotSave'));
    // }
    this.api.post('user/social/signup', data).pipe(take(1)).subscribe((res: any) => {
      if (res.status === 200) {

        this.event.socialData.next(false)
        const data = {
          token: res.token,
          name: res.data.name,
          userId: res.data._id,
          social_id: res?.data?.social_id
        };
        this.storage.setUser(data).then(() => {
          this.event.setLoginEmmit(true);
        });
        if (this.storage.getData('navigateData')) {
          let url = this.storage.getData('navigateData');
          if (url == 'cart') {
            this.router.navigate(['/checkout']);
            setTimeout(() => {
              window.location.reload();
            }, 50);
          } else {
            if (this.cyodData?.status == 'notSave') {
              let type = 'socialLogin';
              this.saveFabric(this.cyodData, res.data._id, type);
            } else {
              this.router.navigateByUrl(url);
              setTimeout(() => {
                window.location.reload();
              }, 50);
            }
          }
          // localStorage.removeItem('navigateData');
        } else {
          this.router.navigate(['/profile']);
          setTimeout(() => {
            window.location.reload();
          }, 50);
        }
        this.api.alert(res.message, 'success');
      }
      else {
        this.api.alert(res.message, 'warning')
      }
    }, err => {
      this.api.alert(err, 'error')
    })
  }

  formInit() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth <= 767) {
      if (isPlatformBrowser(this.platformId)) {
        let header = document.getElementsByClassName('page_header');
        header[0].setAttribute('style', 'display:none');
        // let footer = document.getElementsByClassName('footer');
        // footer[0].setAttribute('style', 'display:none');
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        let header = document.getElementsByClassName('page_header');
        header[0].setAttribute('style', 'display:block');
        // let footer = document.getElementsByClassName('footer');
        // footer[0].setAttribute('style', 'display:block');
      }
    }
  }

  @HostListener("window:keydown.enter", ["$event"])
  userLogin() {
    if (this.loginForm.valid) {
      const data = {
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value
      };
      // let cyodData: any
      // if (localStorage.getItem('cyodNotSave')) {
      //   cyodData = JSON.parse(localStorage.getItem('cyodNotSave'));
      // }


      this.api.post(`user/login`, data).subscribe((res: any) => {
        if (res.status === 200) {
          const data = {
            token: res.token,
            name: res.data.name,
            userId: res.data._id,
            social_id: res?.data?.social_id
          };
          this.storage.setUser(data).then(() => {
            this.event.setLoginEmmit(true);
          });
          if (this.storage.getData('navigateData')) {
            let url = this.storage.getData('navigateData');
            if (url == 'cart') {
              this.api.alert(res.message, 'success');
              this.router.navigate(['/checkout']);
            } else {
              if (this.cyodData?.status == 'notSave') {
                this.isLoader = true
                let type = 'normalLogin';
                this.saveFabric(this.cyodData, res.data._id, type);
              } else {
                this.api.alert(res.message, 'success');
                this.router.navigateByUrl(url);
              }
            }
            // localStorage.removeItem('navigateData');
          } else {
            this.api.alert(res.message, 'success');
            this.router.navigate(['/profile']);
          }

        } else if (res.status === 201) {
          this.api.alert(res.message, 'warning');
        } else if (res.status === 202) {
          // console.log(this.loginForm.controls.email.value);

          this.event.loginSignEmit(this.loginForm.controls.email.value);
          this.router.navigate(['/sign-up']);
          this.api.alert(res.message, 'warning');
        }
        else {
          this.api.alert(res.message, 'error');
        }
      }, err => {
        this.api.alert('User not registered', 'error');
      });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }


  forgotPassword() {
    this.dialog.open(ForgotPasswordComponent, {
      width: '550px',
      maxHeight: '80vh',
    });
  }


  submitLogin() {
    this.fb_login.submitLogin();
  }

  saveFabric(dataCyod, user_id, type) {
    let url = this.storage.getData('navigateData');
    const file = this.dataURLtoFile(dataCyod?.dataURL, 'image.png');
    const data = new FormData();
    data.append('product_id', dataCyod?.productId);
    data.append('user_id', user_id);
    data.append('cyod_deisgn_image', file);
    setTimeout(() => {
      this.api.postMultiData(`product/cyod-design-image-save`, data).subscribe((res: any) => {

        if (res.status === 200) {
          // localStorage.setItem('productId', dataCyod?.productId);
          // localStorage.setItem('editedFabricURL', JSON.stringify(dataCyod?.dataURL));
          // localStorage.setItem('imageID', res?.data?._id);
          let dataURLs = this.baseImgUrl + 'product/' + res.data.image;
          // localStorage.setItem('editedFabricURL', JSON.stringify(dataURLs));
          // let cyodEditData = JSON.parse(localStorage.getItem('detailObj'));
          let cyodEditData = this.detailObj;
          const cyodEditDatas = {
            productId: cyodEditData?.productId,
            editCyodPrice: cyodEditData?.editCyodPrice,
            editPatternData: cyodEditData?.editPatternData,
            editedFabricURL: dataURLs,
            customizedImage: res?.data?._id,
            garmentObj: cyodEditData?.garmentObj,
            profitMargin: cyodEditData?.profitMargin
          }
          this.api.post(`user_cyod_product/add`, cyodEditDatas).subscribe((resp: any) => {
            if (res.status === 200) {
              setTimeout(() => {
                this.api.get(`guest-user/delete/${this.gestId}`).subscribe((res: any) => {
                  if (res.status == 200) {
                    this.api.alert('Login Successfully', 'success');
                    this.isLoader = false
                    // localStorage.removeItem('detailObj');
                    localStorage.removeItem('gest_id')
                    this.router.navigateByUrl(url);
                    if (type == 'socialLogin') {
                      setTimeout(() => {
                        window.location.reload();
                      }, 50);
                    }
                    // localStorage.removeItem('cyodNotSave');
                  }

                })

              }, 1000);
            }

          });

        } else {
          this.api.alert('Something went wrong!', 'error');
        }
      });
    }, 50);



  }

  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      let header = document.getElementsByClassName('page_header');
      header[0].setAttribute('style', 'display:block');
      // let footer = document.getElementsByClassName('footer');
      // footer[0].setAttribute('style', 'display:block');
    }
  }

}
