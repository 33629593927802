
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginNewComponent } from './login-new/login-new.component';
import { RegisterNewComponent } from './register-new/register-new.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifiedEmailComponent } from './verified-email/verified-email.component';
import { ReturnComponent } from './return/return.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginNewComponent
      },
      {
        path: 'sign-up',
        component: RegisterNewComponent
      },
      {
        path: 'forgot-password/:id',
        component: ResetPasswordComponent
      },
      {
        path: 'verify-email/:id',
        component: VerifiedEmailComponent
      },
      {
        path: 'redirecting',
        component: ReturnComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
