<div class="page-verify-email">
    <figure>
        <!-- <img src="./assets/images/email.webp" alt=""> -->
    </figure>
    <div class="verify-content" >
        <h1>Please return to desktop website and press “Show Measurements” button to get your exact body measurements.</h1>
        
        <!-- <button routerLink="/home">Back to Home</button> -->
    </div>
</div>
