import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {


  constructor(
    private storage: StorageService
  ) { }

  loading = new BehaviorSubject(false);
  isLoading = this.loading.asObservable();

  profile = new BehaviorSubject(false);

  socialData = new BehaviorSubject<any>(false)

  Login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.Login.asObservable();

  user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  isListingSearch = new BehaviorSubject(false);
  isListingSearchData = this.isListingSearch.asObservable();


  isCurrencyChange = new BehaviorSubject(false);
  isCurrencyChangeData = this.isCurrencyChange.asObservable();

  LoginRoute = new BehaviorSubject(false);
  LoginRouteChange = this.LoginRoute.asObservable();

  isHitHeaderMenu = new BehaviorSubject(false);
  shortingListing = new BehaviorSubject(false);

  cart = new BehaviorSubject(false);
  headerFooterData = new BehaviorSubject<any>(false);
  headerFooterCmsData = new BehaviorSubject<any>(false);
  
  cmsHeader = new BehaviorSubject('');
  isCmsHeader = this.cmsHeader.asObservable();

  sidebarClose = new BehaviorSubject(false);
  isSidebarClose = this.sidebarClose.asObservable();

  isSignChange = new BehaviorSubject('');
  isSignChangeData = this.isSignChange.asObservable();

  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }

  sidebarCloseEmit(data: any) {
    return this.sidebarClose.next(data);
  }
  listingSearchEmit(data: any) {
    return this.isListingSearch.next(data);
  }

  currencyChangeEmit(data: any, selectedData?: any) {
    return this.isCurrencyChange.next(selectedData);
  }

  loginRouteChangeEmit(data: any) {
    return this.LoginRoute.next(data)
  }

  loginSignEmit(data: any) {
    // console.log("+++++++++++++++++++",data);
    
    return this.isSignChange.next(data);
  }

  async setLoginEmmit(isLogin: boolean) {
    return await this.Login.next(isLogin);
  }

  
}
