export const environment = {
  // BASE_API_URL: 'https://admin-shopdrop.dedicatedresource.net/frontend/',
  // BASE_API_URL: 'https://shopdropnew-admin.dedicateddevelopers.us/frontend/',
  // BASE_API_URL: 'https://shopdropnew-admin.dedicatedresource.net/frontend/',
  // IMAGE_BASE_URL1: 'https://shopdropnew-admin.dedicatedresource.net/uploads/',
  // IMAGE_BASE_URL2: 'shopdropnew-admin.dedicatedresource.net/',
  // IMAGE_BASE_URL: 'https://shopdrop.s3.af-south-1.amazonaws.com/',
  // IMAGE_BASE_URL: 'https://d2fnmg1h87nxsl.cloudfront.net/',
  // LOCAL_IMAGE_URL: 'https://admin-shopdrop.dedicatedresource.net/',
  // IMAGE_BASE_URL: 'https://shopdropcdn.dedicatedresource.net/',

  // LIVE URLS
  BASE_API_URL: 'https://admin.shopdrop.shop/frontend/',
  IMAGE_BASE_URL1: 'https://admin.shopdrop.shop/uploads/',
  IMAGE_BASE_URL: 'https://cdn.shopdrop.shop/',
  BLOG_API_URL: 'https://shopdrop-blog.dedicateddevelopers.us/wp-json/',
  GOOGLE_CLIENT_ID: "389711377566-37phu9h6jprbufq41r095tljkoc1nke3.apps.googleusercontent.com",
  production: true
};
